<template>
    <div class="view pa24">
        <div class="mb20">
            <el-tabs v-model="webType">
                <el-tab-pane label="月租车缴费记录" name="monthlyCarRentalPayment"></el-tab-pane>
                <el-tab-pane label="临时车缴费记录" name="temporaryCarPayment"></el-tab-pane>
                <el-tab-pane label="超时未支付" name="overtimeUnpaid"></el-tab-pane>
            </el-tabs>
        </div>
        <div v-if="webType === 'monthlyCarRentalPayment'">
            <monthlyCarRentalPaymentTable />
        </div>
        <div v-if="webType === 'temporaryCarPayment'">
            <temporaryCarPaymentTable />
        </div>
        <div v-if="webType === 'overtimeUnpaid'">
            <overtimeUnpaidTable />
        </div>
    </div>
</template>

<script>
import monthlyCarRentalPaymentTable from "./pages/monthlyCarRentalPaymentTable";
import temporaryCarPaymentTable from "./pages/temporaryCarPaymentTable";
import overtimeUnpaidTable from "./pages/overtimeUnpaidTable";
export default {
    name: "financialCenter",
    components: {
        monthlyCarRentalPaymentTable,
        temporaryCarPaymentTable,
        overtimeUnpaidTable,
    },
    data() {
        return {
            webType: "monthlyCarRentalPayment",
        };
    },
    filters: {

    },
    created() {

    },
    methods: {

    },
};
</script>

<style lang="scss" scoped>
.switchInput {
    display: flex;

    .selectType {
        width: 150px;
    }
}

.operation-left {
    margin-bottom: 20px;

    .el-button {
        width: 95px;
        height: 32px;
        background: #51cbcd;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;

        &:active {
            border-color: #51cbcd;
        }
    }
}
</style>